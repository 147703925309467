// Home.js
import React, { useState, useEffect } from "react";
import HomeInitialView from "../Initial/Initial";
import HomeTryOnComponents from "../TryOn/TryOn";
import VisualSearchModal from "../VisualSearch/VisualSearch";
import { useItemSelection } from "../../hooks/useItemSelection";
import "./Home.css";
import similarItemsData from "../../data/SimilarItems";

const Home = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showTryOnComponents, setShowTryOnComponents] = useState(false);
  const [isFinalized, setIsFinalized] = useState(false);
  const [webcamStream, setWebcamStream] = useState(null);
  const [useWebcam, setUseWebcam] = useState(false);
  const [userImage, setUserImage] = useState(null);

  const { items, setItems, finalizedItems, handleItemSelect, handleItemClick } =
    useItemSelection();

  const handleVisualSearchClick = () => {
    setShowModal(true);
  };

  const handleImageUpload = (file) => {
    if (!file || !(file instanceof Blob)) {
      console.error("Invalid file type. Please upload an image.");
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        setUserImage(img);
        setUseWebcam(false);
      };
      img.onerror = () => {
        console.error("Failed to load image from file upload.");
      };
    };
    reader.readAsDataURL(file);
  };

  const startWebcam = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        setWebcamStream(stream);
        setUseWebcam(true);
      })
      .catch((err) => {
        console.error("Error accessing webcam: ", err);
        setUseWebcam(false);
      });
  };
  const handleUseWebcam = () => {
    setUserImage(null); // Clear any uploaded image
    startWebcam();
  };

  const handleCloseWebcam = () => {
    if (webcamStream) {
      webcamStream.getTracks().forEach((track) => track.stop());
      setWebcamStream(null);
    }
    setUseWebcam(false);
  };

  // Cleanup webcam stream on unmount
  useEffect(() => {
    return () => {
      if (webcamStream) {
        webcamStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [webcamStream]);
  const handleModalClose = (newItems) => {
    if (newItems && newItems.length > 0) {
      setSelectedItems((prevItems) => [...prevItems, ...newItems]);
    }
    setShowModal(false);
  };

  const handleTryOnClick = () => {
    setShowTryOnComponents(true);
  };

  const handleFinalizeItems = () => {
    setShowTryOnComponents(false);
    //console.log('items -->', items);
    //console.log('finalizedItems -->', finalizedItems);
    //console.log('selectedItems -->', selectedItems);

    // Update selectedItems based on finalizedItems
    const updatedSelectedItems = selectedItems.map((item) => {
      const isFinalized =
        finalizedItems &&
        finalizedItems.find((finalizedItem) => finalizedItem.id === item.id);
      return isFinalized
        ? { ...item, selected: true }
        : { ...item, selected: false };
    });

    // Add matching items from similarItemsData.necklaces to selectedItems
    finalizedItems.forEach((itm) => {
      const isSimilar = similarItemsData.necklaces.find(
        (similarItem) => similarItem.id === itm.id
      );

      if (isSimilar) {
        const existingItem = updatedSelectedItems.find(
          (item) => item.id === itm.id
        );

        if (!existingItem) {
          // Add the item if it's not already in updatedSelectedItems
          updatedSelectedItems.push({
            id: isSimilar.id,
            name: isSimilar.name,
            image: isSimilar.image,
            price: isSimilar.price,
            selected: true,
          });
        }
      }
    });

    // Sort the updatedItems: finalized items (selected) on top, non-finalized items at the bottom
    const sortedItems = updatedSelectedItems.sort((a, b) => {
      return b.selected - a.selected;
    });

    setSelectedItems(sortedItems);
  };

  const handleCheckboxChange = (index) => {
    const updatedItems = selectedItems.map((item, i) =>
      i === index ? { ...item, selected: !item.selected } : item
    );
    setSelectedItems(updatedItems);
  };

  return (
    <div>
      {!showTryOnComponents ? (
        <HomeInitialView
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleVisualSearchClick={handleVisualSearchClick}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          finalizedItems={finalizedItems}
          isFinalized={isFinalized}
          handleCheckboxChange={handleCheckboxChange}
          handleTryOnClick={handleTryOnClick}
        />
      ) : (
        <HomeTryOnComponents
          items={items}
          setItems={setItems}
          handleItemSelect={handleItemSelect}
          handleItemClick={handleItemClick}
          userImage={userImage}
          setUserImage={setUserImage} // Make sure this is passed down
          useWebcam={useWebcam}
          webcamStream={webcamStream}
          handleImageUpload={handleImageUpload}
          handleUseWebcam={handleUseWebcam}
          handleCloseWebcam={handleCloseWebcam}
          handleFinalizeItems={handleFinalizeItems}
        />
      )}

      <VisualSearchModal show={showModal} onClose={handleModalClose} />
    </div>
  );
};

export default Home;
