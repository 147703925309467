import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Canvas from "../Canavas/Canavas";
import Controls from "../Controls/Controls";
import "./TryOn.css";
import model1 from "../../assets/model1.png";
import model2 from "../../assets/model2.jpg";
import model3 from "../../assets/model3.png";

const TryOn = ({
  items,
  setItems,
  handleItemSelect,
  handleItemClick,
  userImage,
  setUserImage, // Ensure setUserImage is passed here
  webcamStream,
  useWebcam,
  handleImageUpload,
  handleUseWebcam,
  handleCloseWebcam,
  handleFinalizeItems,
}) => {
  const finalizeSelection = () => {
    handleFinalizeItems();
  };

  return (
    <div className="home-container">
      <div className="canvas-and-panel">
        <div className="canvas-container">
          <Canvas
            items={items}
            setItems={setItems}
            userImage={userImage}
            setUserImage={setUserImage} // Pass setUserImage to Canvas
            useWebcam={useWebcam}
            webcamStream={webcamStream}
            models={[model1, model2, model3]}
            handleImageUpload={handleImageUpload}
            handleUseWebcam={handleUseWebcam}
            handleCloseWebcam={handleCloseWebcam}
          />
        </div>
      </div>
      <div className="control-wrapper">
        <div className="tab-wrapper">
          <Tabs defaultActiveKey="jewellery" id="fill-tab-example" fill>
            <Tab eventKey="jewellery" title="Jewellery">
              <div className="side-panel">
                <div className="item-list">
                  <Controls
                    onItemSelect={handleItemSelect}
                    onItemClick={handleItemClick}
                    category="jewellery"
                  />
                </div>
              </div>
            </Tab>
            <Tab eventKey="sarees" title="Sarees">
              <div className="sarees-thumbnails">
                <div className="sarees-list">
                  <Controls
                    onItemSelect={handleItemSelect}
                    onItemClick={handleItemClick}
                    category="sarees"
                  />
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
        <button className="finalize-button" onClick={finalizeSelection}>
          Finalize Selection
        </button>
      </div>
    </div>
  );
};

export default TryOn;
