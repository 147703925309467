// Importing images for gold items
import goldChain1 from "../assets/gold_chain_1.webp";
import goldChain2 from "../assets/gold_chain_2.webp";
import goldChain3 from "../assets/gold_chain_3.webp";
import goldChain4 from "../assets/gold_chain_4.webp";
import goldNecklace1 from "../assets/gold_necklace_1.png";
import goldNecklace2 from "../assets/gold_necklace_2.png";
import goldNecklace3 from "../assets/gold_necklace_3.png";
import goldNecklace4 from "../assets/gold_necklace_4.png";
import goldEarring1 from "../assets/gold_earring_1.png";
import goldEarring2 from "../assets/gold_earring_2.png";
import goldEarring3 from "../assets/gold_earring_3.png";
import goldEarring4 from "../assets/gold_earring_4.png";
import goldRing1 from "../assets/gold_ring_1.png";
import goldRing2 from "../assets/gold_ring_2.png";
import goldRing3 from "../assets/gold_ring_3.png";
import goldRing4 from "../assets/gold_ring_4.png";

const goldItems = [
  {
    name: "Gold Necklaces",
    subCategories: [
      {
        name: "Diamond Necklace",
        image: goldNecklace1,
        items: [
          {
            id: 1,
            name: "Diamond Necklace",
            image: goldNecklace1,
            weight: "20g",
            price: 1000,
            quantity: 1,
          },
        ],
      },
      {
        name: "Beaded Necklaces",
        image: goldNecklace2,
        items: [
          {
            id: 2,
            name: "Gold Beaded Necklaces",
            image: goldNecklace2,
            weight: "25g",
            price: 1250,
            quantity: 1,
          },
        ],
      },
      {
        name: "Pendant Sets",
        image: goldNecklace3,
        items: [
          {
            id: 3,
            name: "Pendant Sets",
            image: goldNecklace3,
            weight: "25g",
            price: 1250,
            quantity: 1,
          },
        ],
      },
      {
        name: "Chokers",
        image: goldNecklace4,
        items: [
          {
            id: 4,
            name: " Chokers ",
            image: goldNecklace4,
            weight: "25g",
            price: 1250,
            quantity: 1,
          },
        ],
      },
    ],
  },
  {
    name: "Gold Chains",
    subCategories: [
      {
        name: "Diamond Necklace",
        image: goldChain1,
        items: [
          {
            id: 5,
            name: "Diamond Necklace",
            image: goldChain1,
            weight: "10g",
            price: 500,
            quantity: 1,
          },
        ],
      },
      {
        name: "Diamond Necklace",
        image: goldChain2,
        items: [
          {
            id: 6,
            name: "Diamond Necklace",
            image: goldChain2,
            weight: "15g",
            price: 750,
            quantity: 1,
          },
        ],
      },
      {
        name: "Diamond Necklace",
        image: goldChain3,
        items: [
          {
            id: 7,
            name: "Diamond Necklace",
            image: goldChain3,
            weight: "15g",
            price: 750,
            quantity: 1,
          },
        ],
      },
      {
        name: "Diamond Necklace",
        image: goldChain4,
        items: [
          {
            id: 8,
            name: "Diamond Necklace",
            image: goldChain4,
            weight: "15g",
            price: 750,
            quantity: 1,
          },
        ],
      },
    ],
  },
  {
    name: "Earrings",
    subCategories: [
      {
        name: "Diamond Earrings",
        image: goldEarring1,
        items: [
          {
            id: 9,
            name: "Diamond Earrings",
            image: goldEarring1,
            weight: "5g",
            price: 300,
            quantity: 1,
          },
        ],
      },
      {
        name: "Hoop Earrings",
        image: goldEarring2,
        items: [
          {
            id: 10,
            name: "Gold Hoop Earring 1",
            image: goldEarring2,
            weight: "7g",
            price: 400,
            quantity: 1,
          },
        ],
      },
      {
        name: "Gemstone Earrings",
        image: goldEarring3,
        items: [
          {
            id: 11,
            name: "Gemstone Earrings",
            image: goldEarring3,
            weight: "7g",
            price: 400,
            quantity: 1,
          },
        ],
      },
      {
        name: "Diamond Earrings",
        image: goldEarring4,
        items: [
          {
            id: 12,
            name: "Diamond Earrings",
            image: goldEarring4,
            weight: "7g",
            price: 400,
            quantity: 1,
          },
        ],
      },
    ],
  },
  {
    name: "Gold Rings",
    subCategories: [
      {
        name: "Engagement Rings",
        image: goldRing1,
        items: [
          {
            id: 13,
            name: "Gold Engagement Ring 1",
            image: goldRing1,
            weight: "3g",
            price: 250,
            quantity: 1,
          },
        ],
      },
      {
        name: "Wedding Rings",
        image: goldRing2,
        items: [
          {
            id: 14,
            name: "Gold Wedding Ring 1",
            image: goldRing2,
            weight: "4g",
            price: 300,
            quantity: 1,
          },
        ],
      },
      {
        name: "Casual Rings",
        image: goldRing3,
        items: [
          {
            id: 15,
            name: "Casual Rings",
            image: goldRing3,
            weight: "4g",
            price: 300,
            quantity: 1,
          },
        ],
      },
      {
        name: "Cocktail Rings",
        image: goldRing4,
        items: [
          {
            id: 16,
            name: "Cocktail Rings",
            image: goldRing4,
            weight: "4g",
            price: 300,
            quantity: 1,
          },
        ],
      },
    ],
  },
];

export default goldItems;
