import React from "react";
import saree1 from "../../assets/item1.png";
import saree2 from "../../assets/item2.png";
import saree3 from "../../assets/item3.png";
import saree4 from "../../assets/item4.png";
import saree5 from "../../assets/item5.png";
import saree6 from "../../assets/item6.png";
import jewelleryImage1 from "../../assets/jewellery.jpg";
import jewelleryImage2 from "../../assets/jewellery2.jpg";
import jewelleryImage3 from "../../assets/jewellery3.jpg";
import jewelleryImage4 from "../../assets/jewellery4.png";
import jewelleryImage5 from "../../assets/jewellery5.jpg";
import bindi from "../../assets/bindi.png";
import necklace1 from "../../assets/necklace1.png";
import earring1_1 from "../../assets/earring1_1.png";
import earring1_2 from "../../assets/earring1_2.png";
import earring1_3 from "../../assets/earring1_3.png";
import necklace1_2 from "../../assets/necklace1_2.png";

import necklace2 from "../../assets/necklace2.png";
import necklace2_1 from "../../assets/necklace2_1.png";
import earring2_1 from "../../assets/earring2_1.png";
import earring2_2 from "../../assets/earring2_2.png";
import earring2_3 from "../../assets/earring2_3.png";

import necklace3 from "../../assets/necklace3.png";
import necklace3_1 from "../../assets/necklace3_1.png";
import earring3_1 from "../../assets/earring3_1.png";
import earring3_2 from "../../assets/earring3_2.png";
import earring3_3 from "../../assets/earring3_3.png";

import necklace4 from "../../assets/necklace4.png";
import necklace4_1 from "../../assets/necklace4_1.png";
import necklace4_2 from "../../assets/necklace4_2.png";
import earring4_1 from "../../assets/earring4_1.png";
import earring4_2 from "../../assets/earring4_2.png";
import earring4_3 from "../../assets/earring4_3.png";

import necklace5 from "../../assets/necklace5.png";
import earring5_1 from "../../assets/earring5_1.png";
import earring5_2 from "../../assets/earring5_2.png";
import earring5_3 from "../../assets/earring5_3.png";
import "./Controls.css";

const Controls = ({ onItemSelect, onItemClick, category }) => {
  const sarees = [
    { id: 1, src: saree1, name: "Saree 1", relatedImage: bindi }, // Add related image
    { id: 2, src: saree2, name: "Saree 2", relatedImage: bindi },
    { id: 3, src: saree3, name: "Saree 3", relatedImage: bindi },
    { id: 4, src: saree4, name: "Saree 4", relatedImage: bindi },
    { id: 5, src: saree5, name: "Saree 5", relatedImage: bindi },
    { id: 6, src: saree6, name: "Saree 6", relatedImage: bindi }, // Re-use if needed
  ];

  const jewelrySets = [
    {
      id: 1,
      mainImage: jewelleryImage1,
      items: [
        { id: 7, similarId: 2, src: necklace1, name: "Necklace 1" },
        { id: 8, similarId: 2, src: earring1_1, name: "Earring 1_1" },
        { id: 9, similarId: 2, src: earring1_2, name: "Earring 1_2" },
        { id: 27, similarId: 6, src: earring1_3, name: "Earring 1_3" },
        { id: 28, similarId: 6, src: necklace1_2, name: "Necklace 1_2" },
      ],
    },
    {
      id: 2,
      mainImage: jewelleryImage2,
      items: [
        { id: 11, similarId: 6, src: necklace2, name: "Necklace 2" },
        { id: 12, similarId: 6, src: earring2_1, name: "Earring 2_1" },
        { id: 13, similarId: 6, src: earring2_2, name: "Earring 2_2" },
        { id: 29, similarId: 6, src: necklace2_1, name: "Necklace 2_1" },
        { id: 30, similarId: 6, src: earring2_3, name: "Earring 2_3" },
      ],
    },
    {
      id: 3,
      mainImage: jewelleryImage3,
      items: [
        { id: 15, similarId: 3, src: necklace3, name: "Necklace 3" },
        { id: 31, similarId: 3, src: necklace3_1, name: "Necklace 3_1" },
        { id: 16, similarId: 3, src: earring3_1, name: "Earring 3_1" },
        { id: 17, similarId: 3, src: earring3_2, name: "Earring 3_2" },
        { id: 32, similarId: 3, src: earring3_3, name: "Earring 3_3" },
      ],
    },
    {
      id: 4,
      mainImage: jewelleryImage4,
      items: [
        { id: 19, similarId: 4, src: necklace4, name: "Necklace 4" },
        { id: 20, similarId: 4, src: earring4_1, name: "Earring 4_1" },
        { id: 21, similarId: 4, src: earring4_2, name: "Earring 4_2" },
      ],
    },
    {
      id: 5,
      mainImage: jewelleryImage5,
      items: [
        { id: 23, similarId: 5, src: necklace5, name: "Necklace 5" },
        { id: 24, similarId: 5, src: earring5_1, name: "Earring 5_1" },
        { id: 25, similarId: 5, src: earring5_2, name: "Earring 5_2" },
      ],
    },
  ];

  const handleCheckboxChange = (jewelrySet, isChecked) => {
    const setId =
      jewelrySet.items.length > 0 ? jewelrySet.items[0].similarId : null;
    if (isChecked) {
      onItemSelect({
        id: setId,
        mainImage: jewelrySet.mainImage,
      });
    } else {
      onItemSelect(
        {
          id: setId,
          mainImage: jewelrySet.mainImage,
        },
        true
      );
    }
  };

  const handleImageClick = (set, relatedSet = null) => {
    // Click handler for saree
    set.items.forEach((item) => onItemClick(item));

    // Trigger the related item if available
    if (relatedSet) {
      onItemClick(relatedSet);
    }
  };

  return (
    <div className="controls-container">
      {category === "sarees" && (
        <div className="sarees-container">
          {sarees.map((item) => (
            <div key={item.id} className="saree-item">
              <img
                src={item.src}
                alt={item.name}
                className="saree-image"
                onClick={() =>
                  handleImageClick(
                    {
                      items: [{ id: item.id, src: item.src, name: item.name }],
                    },
                    {
                      id: `related-${item.id}`,
                      src: item.relatedImage,
                      name: `Related to ${item.name}`,
                    }
                  )
                }
              />
              {/* <p>{item.name}</p> */}
            </div>
          ))}
        </div>
      )}
      {category === "jewellery" && (
        <div className="jewelry-container">
          {jewelrySets.map((jewelrySet) => (
            <div key={jewelrySet.id} className="jewelry-item">
              <img
                src={jewelrySet.mainImage}
                alt={`Jewelry Set ${jewelrySet.id}`}
                className="jewelry-main-image"
                onClick={() => handleImageClick(jewelrySet)}
              />
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="jewelry-checkbox"
                  onChange={(e) =>
                    handleCheckboxChange(jewelrySet, e.target.checked)
                  }
                />
              </label>
              {/* <p>Jewelry Set {jewelrySet.id}</p> */}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Controls;
