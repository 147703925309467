import React, { useState, useEffect, useRef } from "react";
import { FaCamera, FaImage } from "react-icons/fa";
import Webcam from "react-webcam";
import "./VisualSearch.css";
import SimilarItemsData from "../../data/SimilarItems";

const VisualSearchModal = ({ show, onClose }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [showOptions, setShowOptions] = useState(true);
  const [similarItems, setSimilarItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isWebcamOpen, setIsWebcamOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, onClose]);

  if (!show) return null;

  const handleImageUploadClick = (e) => {
    e.preventDefault(); // Prevent default behavior
    e.stopPropagation(); // Stop event propagation
    document.getElementById("imageUpload").click();
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setShowOptions(false);
      setSimilarItems(SimilarItemsData.necklaces);
    }
  };

  const handleWebcamCapture = (imgSrc) => {
    setSelectedImage(imgSrc);
    setShowOptions(false);
    setIsWebcamOpen(false);
    setSimilarItems(SimilarItemsData.necklaces);
  };

  const handleSelectItem = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((i) => i.id !== item.id));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleDone = () => {
    onClose(selectedItems);
  };

  return (
    <div className="image-search-container" ref={dropdownRef}>
      {showOptions && (
        <div className="image-search-dropdown">
          <div className="image-search-option" onClick={handleImageUploadClick}>
            <FaImage className="option-icon" />
            <span>Search with photo</span>
          </div>
          <div
            className="image-search-option"
            onClick={() => setIsWebcamOpen(true)}
          >
            <FaCamera className="option-icon" />
            <span>Search with camera</span>
          </div>
        </div>
      )}

      <input
        type="file"
        id="imageUpload"
        style={{ display: "none" }}
        onChange={handleImageUpload}
      />

      {isWebcamOpen && (
        <div className="webcam-container">
          <Webcam
            audio={false}
            screenshotFormat="image/jpeg"
            className="webcam-view"
            onUserMedia={() => console.log("Webcam is ready")}
          >
            {({ getScreenshot }) => (
              <button
                className="btn capture-btn"
                onClick={() => {
                  const imageSrc = getScreenshot();
                  handleWebcamCapture(imageSrc);
                }}
              >
                Capture
              </button>
            )}
          </Webcam>
        </div>
      )}

      {selectedImage && (
        <div className="selected-image-preview">
          <img src={selectedImage} alt="Selected" />
        </div>
      )}

      {similarItems.length > 0 && (
        <div className="similar-items">
          <div className="similar-done">
            <h3>Similar Items</h3>
            <button
              className="btn btn-done"
              onClick={handleDone}
              disabled={selectedItems.length === 0}
            >
              Done
            </button>
          </div>
          <div className="items-grid">
            {similarItems.map((item) => (
              <div key={item.id} className="similar-item">
                <img src={item.image} alt={item.name} />
                <p>{item.name}</p>
                <p>${item.price}</p>
                <button onClick={() => handleSelectItem(item)}>
                  {selectedItems.includes(item) ? "✔️ Selected" : "🤍 Wishlist"}
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default VisualSearchModal;
