import React, { useRef, useEffect, useState } from "react";
import "./Canavas.css";
import Button from "react-bootstrap/Button";
import { BiSolidTrash, BiCamera } from "react-icons/bi";
import ImageOptionDialogComponent from "../image-option-dailog";
import WebcamCaptureComponent from "../../components/webcam-capture";

const Canvas = ({
  items,
  setItems,
  userImage,
  setUserImage,
  useWebcam,
  webcamStream,
  handleImageUpload,
  handleUseWebcam,
  handleCloseWebcam,
  models,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedModelIndex, setSelectedModelIndex] = useState(0);
  const [draggingIndex, setDraggingIndex] = useState(null);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [isNearTrash, setIsNearTrash] = useState(false);
  const [lastTap, setLastTap] = useState(0);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  // const trashRef = useRef(null);
  const [modelImage, setModelImage] = useState(null);
  const [showWebcam, setShowWebcam] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);

  // Load the selected model image
  useEffect(() => {
    if (models && models.length > 0) {
      const img = new Image();
      img.src = models[selectedModelIndex];
      img.onload = () => {
        setModelImage(img);
      };
    }
  }, [selectedModelIndex, models]);

  // Handle webcam stream
  useEffect(() => {
    if (useWebcam && webcamStream && videoRef.current) {
      videoRef.current.srcObject = webcamStream;
      videoRef.current.play();
    } else if (!useWebcam && videoRef.current) {
      videoRef.current.srcObject = null;
    }
  }, [useWebcam, webcamStream]);

  // Main drawing function
  useEffect(() => {
    const draw = () => {
      if (!canvasRef.current) return;

      const ctx = canvasRef.current.getContext("2d");
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

      // Draw the model image
      if (modelImage instanceof HTMLImageElement) {
        ctx.drawImage(
          modelImage,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
      }

      // Draw user image, captured image, or webcam stream
      if (userImage instanceof HTMLImageElement && !useWebcam) {
        ctx.drawImage(
          userImage,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
      } else if (capturedImage instanceof HTMLImageElement) {
        ctx.drawImage(
          capturedImage,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
      } else if (useWebcam && videoRef.current instanceof HTMLVideoElement) {
        ctx.drawImage(
          videoRef.current,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
      }

      // Draw the draggable items (sarees and jewelry)
      items.forEach((item) => {
        if (item.img instanceof HTMLImageElement && item.img.complete) {
          ctx.drawImage(item.img, item.position.x, item.position.y);
        }
      });

      requestAnimationFrame(draw);
    };

    draw();
  }, [items, userImage, useWebcam, modelImage, capturedImage]);

  // Event handlers for dragging items
  const startDragging = (x, y) => {
    items.forEach((item, index) => {
      if (
        x >= item.position.x &&
        x <= item.position.x + item.img.width &&
        y >= item.position.y &&
        y <= item.position.y + item.img.height
      ) {
        setDraggingIndex(index);
        setDragOffset({ x: x - item.position.x, y: y - item.position.y });
      }
    });
  };

  const dragItem = (x, y) => {
    if (draggingIndex !== null) {
      setItems((prevItems) => {
        const newItems = [...prevItems];
        newItems[draggingIndex].position = {
          x: x - dragOffset.x,
          y: y - dragOffset.y,
        };
        return newItems;
      });
    }
  };

  const stopDragging = () => {
    setDraggingIndex(null);
  };

  // Mouse event handlers
  const handleMouseDown = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    startDragging(x, y);
  };

  const handleMouseMove = (e) => {
    if (draggingIndex !== null) {
      const rect = canvasRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      // const trashRect = trashRef.current.getBoundingClientRect();
      // const isNearTrash =
      //   e.clientX >= trashRect.left &&
      //   e.clientX <= trashRect.right &&
      //   e.clientY >= trashRect.top &&
      //   e.clientY <= trashRect.bottom;

      // setIsNearTrash(isNearTrash);

      dragItem(x, y);
    }
  };

  const handleMouseUp = () => {
    if (isNearTrash && draggingIndex !== null) {
      // Remove the item if it's near the trash can when mouse is released
      setItems((prevItems) =>
        prevItems.filter((_, index) => index !== draggingIndex)
      );
    }
    stopDragging();
    setIsNearTrash(false);
  };

  // Touch event handlers
  const handleTouchStart = (e) => {
    e.preventDefault();
    const currentTime = new Date().getTime();
    const tapLength = currentTime - lastTap;

    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.touches[0].clientX - rect.left;
    const y = e.touches[0].clientY - rect.top;

    if (tapLength < 300 && tapLength > 0) {
      // Double tap to remove item
      setItems((prevItems) => {
        return prevItems.filter((item) => {
          return !(
            x >= item.position.x &&
            x <= item.position.x + item.img.width &&
            y >= item.position.y &&
            y <= item.position.y + item.img.height
          );
        });
      });
    } else {
      // Single tap to start dragging
      startDragging(x, y);
    }

    setLastTap(currentTime);
  };

  const handleTouchMove = (e) => {
    if (draggingIndex !== null) {
      e.preventDefault();
      const rect = canvasRef.current.getBoundingClientRect();
      const x = e.touches[0].clientX - rect.left;
      const y = e.touches[0].clientY - rect.top;

      // const trashRect = trashRef.current.getBoundingClientRect();
      // const isNearTrash =
      //   e.touches[0].clientX >= trashRect.left &&
      //   e.touches[0].clientX <= trashRect.right &&
      //   e.touches[0].clientY >= trashRect.top &&
      //   e.touches[0].clientY <= trashRect.bottom;

      // setIsNearTrash(isNearTrash);

      dragItem(x, y);
    }
  };

  const handleTouchEnd = () => {
    if (isNearTrash && draggingIndex !== null) {
      // Remove the item if it's near the trash can when touch ends
      setItems((prevItems) =>
        prevItems.filter((_, index) => index !== draggingIndex)
      );
    }
    stopDragging();
    setIsNearTrash(false);
  };

  // Add touch event listeners
  useEffect(() => {
    const canvas = canvasRef.current;

    // Attach touch event listeners
    canvas.addEventListener("touchstart", handleTouchStart, { passive: false });
    canvas.addEventListener("touchmove", handleTouchMove, { passive: false });
    canvas.addEventListener("touchend", handleTouchEnd, { passive: false });

    // Cleanup event listeners on component unmount
    return () => {
      canvas.removeEventListener("touchstart", handleTouchStart);
      canvas.removeEventListener("touchmove", handleTouchMove);
      canvas.removeEventListener("touchend", handleTouchEnd);
    };
  }, [handleTouchStart, handleTouchMove, handleTouchEnd]);

  // Handle double-click to remove items (for desktop)
  const handleDoubleClick = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setItems((prevItems) => {
      return prevItems.filter((item) => {
        return !(
          x >= item.position.x &&
          x <= item.position.x + item.img.width &&
          y >= item.position.y &&
          y <= item.position.y + item.img.height
        );
      });
    });
  };

  // Function to change the model image
  const changeModel = () => {
    handleCloseWebcam();
    setUserImage(null);
    setCapturedImage(null);
    setSelectedModelIndex((prevIndex) => (prevIndex + 1) % models.length);
  };

  // Functions to handle image options
  const handleSelfClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpload = (event) => {
    setCapturedImage(null);
    const file = event.target.files[0];
    if (file) {
      handleImageUpload(file);
    }
    handleClose();
  };

  const handleCapture = (imageSrc) => {
    setShowWebcam(false);

    const img = new Image();
    img.src = imageSrc;

    img.onload = () => {
      setCapturedImage(img);
      handleCloseWebcam();
    };

    img.onerror = () => {
      console.error("Failed to load the captured image.");
    };
  };

  const handleWebcamOpen = () => {
    setShowWebcam(true);
    handleClose();
  };

  return (
    <div
      className="canvas-container"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onDoubleClick={handleDoubleClick}
    >
      <canvas ref={canvasRef} width="625" height="895" />
      {useWebcam && <video ref={videoRef} style={{ display: "none" }} />}

      <div className="canvas-button-group">
        <div className="button-with-options">
          <button className="model-button" onClick={changeModel}>
            Model
          </button>
          <button className="live-button" onClick={handleSelfClick}>
            Self
          </button>

          <ImageOptionDialogComponent
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onUpload={handleUpload}
            onWebcam={handleWebcamOpen}
          />
          <WebcamCaptureComponent
            open={showWebcam}
            onClose={() => setShowWebcam(false)}
            onCapture={handleCapture}
          />
        </div>
      </div>

      {/* <Button
        className={`btn_trash ${isNearTrash ? "highlight-trash" : ""}`}
        ref={trashRef}
      >
        <BiSolidTrash />
      </Button>
      <Button className="btn_capture">
        <BiCamera />
      </Button> */}
    </div>
  );
};

export default Canvas;
