// useItemSelection.js
import { useState } from "react";

export const useItemSelection = () => {
  const [items, setItems] = useState([]);
  const [finalizedItems, setFinalizedItems] = useState([]);
  const [userImage, setUserImage] = useState(null);
  const [useWebcam, setUseWebcam] = useState(false);
  const [webcamStream, setWebcamStream] = useState(null);

  const handleItemSelect = (jewelrySet, isRemoval = false) => {
    console.log('handleItemSelect- item ->', jewelrySet);
    if (isRemoval) {
      setFinalizedItems((prevItems) =>
        prevItems.filter((item) => item.id !== jewelrySet.id)
      );
    } else {
      setFinalizedItems((prevItems) => [
        ...prevItems,
        jewelrySet
      ]);
    }
  };

  const handleItemClick = (item, isRemoval = false) => {
    console.log('handleItemClick - item ->', item);
    if (isRemoval) {
      setItems((prevItems) => prevItems.filter((i) => i.img.src !== item.src));
    } else {
      const img = new Image();
      img.src = item.src;
      img.onload = () => {
        setItems((prevItems) => [
          ...prevItems,
          { img, position: { x: 100, y: 100 } },
        ]);
      };
    }
  };

  const handleImageUpload = (file) => {
    if (!file || !(file instanceof Blob)) {
      console.error("Invalid file type. Please upload an image.");
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        setUserImage(img);
        setUseWebcam(false);
      };
      img.onerror = () => {
        console.error("Failed to load image from file upload.");
      };
    };
    reader.readAsDataURL(file);
  };

  const handleUseWebcam = () => {
    setUserImage(null);
    setUseWebcam(true);
    startWebcam();
  };

  const startWebcam = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        setWebcamStream(stream);
      })
      .catch((err) => console.error("Error accessing webcam: ", err));
  };

  const handleCloseWebcam = () => {
    if (webcamStream) {
      webcamStream.getTracks().forEach((track) => track.stop());
      setWebcamStream(null);
    }
    setUseWebcam(false);
  };

  return {
    items,
    setItems,
    finalizedItems,
    userImage,
    useWebcam,
    handleItemSelect,
    handleItemClick,
    handleImageUpload,
    handleUseWebcam,
    handleCloseWebcam,
  };
};
