import React from "react";
import {
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { PhotoCamera, Image as ImageIcon } from "@mui/icons-material";

const ImageOptionDialogComponent = ({
  anchorEl,
  open,
  onClose,
  onUpload,
  onWebcam,
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "#35374B",
          borderRadius: "8px",
          width: "200px",
          maxHeight: "300px",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "white",
        },
      }}
    >
      <List sx={{ width: "100%" }}>
        <ListItem
          component="label"
          sx={{ borderBottom: "2px solid rgba(255, 255, 255, 0.1)" }}
        >
          <ListItemText
            primary="Try with photo"
            sx={{ whiteSpace: "nowrap", fontWeight: 300 }}
          />
          <ListItemIcon sx={{ marginLeft: "150px", color: "white" }}>
            <ImageIcon fontSize="small" />
          </ListItemIcon>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={onUpload} // This should correctly pass the event
          />
        </ListItem>
        <ListItem onClick={onWebcam} sx={{ width: "100%" }}>
          <ListItemText
            primary="Try with camera"
            sx={{ whiteSpace: "nowrap", fontWeight: 300 }}
          />
          <ListItemIcon sx={{ marginLeft: "150px", color: "white" }}>
            <PhotoCamera fontSize="small" />
          </ListItemIcon>
        </ListItem>
      </List>
    </Popover>
  );
};

export default ImageOptionDialogComponent;
