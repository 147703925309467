import React, { useRef, useState, useEffect } from "react";
import Webcam from "react-webcam";
import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const getVideoConstraints = (facingMode) => {
  return { facingMode: facingMode };
};

const WebcamCaptureComponent = ({ open, onClose, onCapture, canvasHeight }) => {
  const webcamRef = useRef(null);
  const [facingMode, setFacingMode] = useState(
    isMobile ? "environment" : "user"
  );
  const [videoConstraints, setVideoConstraints] = useState(
    getVideoConstraints(facingMode)
  );

  useEffect(() => {
    // Whenever the canvas height changes, ensure the webcam height matches it.
    setVideoConstraints({ ...videoConstraints, height: canvasHeight });
  }, [canvasHeight]);

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    onCapture(imageSrc);
    onClose();
  };

  const handleUserMediaError = (error) => {
    console.error("Webcam error:", error);
    if (error.name === "OverconstrainedError") {
      // Fallback to user-facing camera if environment-facing camera is not available
      setFacingMode("user");
      setVideoConstraints(getVideoConstraints("user"));
    }
  };

  const flipCamera = () => {
    const newFacingMode = facingMode === "user" ? "environment" : "user";
    setFacingMode(newFacingMode);
    setVideoConstraints(getVideoConstraints(newFacingMode));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          transform: "translateX(-20%)", // Shifts the whole dialog to the left by 20%
        },
      }}
    >
      {/* <DialogTitle>Capture Image</DialogTitle>{" "} */}
      {/* Keeping the original title */}
      {/* <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          // padding: "20px",
        }}
      > */}
      <div
        style={{
          position: "relative",
          width: "100%",
          height: canvasHeight || "660px", // Increase height to 600px (or set canvasHeight dynamically)
          display: "inline-block",
          overflow: "hidden", // Ensure the webcam fits within this space
        }}
      >
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          onUserMediaError={handleUserMediaError}
          style={{ width: "100%", height: "100%", objectFit: "cover" }} // Full size of container
        />
        {/* Circle Overlay */}
        <div
          style={{
            position: "absolute",
            top: "30%",
            left: "50%",
            width: "300px", // Customize based on desired size
            height: "350px", // Customize based on desired size
            border: "3px dashed red", // Red dashed border
            borderRadius: "50%", // Circle shape
            transform: "translate(-50%, -50%)", // Center the circle
            pointerEvents: "none", // Make sure the circle doesn't interfere with user interaction
          }}
        ></div>

        {/* Adding 'Capture Image' text over the camera feed */}
        <div
          style={{
            position: "absolute", // Position text over the feed
            top: "10px", // Adjust position relative to the top of the feed
            right: "55%",
            transform: "translateX(-50%)", // Center the text horizontally
            color: "#fff", // White text for visibility over the feed
            fontSize: "24px", // Large text for visibility
            fontWeight: "bold", // Bold text to make it stand out
          }}
        >
          Capture Image
        </div>

        {/* Buttons over the camera feed */}
        <div
          style={{
            position: "absolute", // Position buttons over the feed
            bottom: "10px", // Adjust distance from bottom of the feed
            left: "50%",
            transform: "translateX(-50%)", // Center the buttons horizontally
            display: "flex",
            gap: "10px", // Add space between buttons
          }}
        >
          <Button variant="contained" onClick={handleCapture}>
            Capture
          </Button>
          <Button variant="contained" onClick={flipCamera}>
            Flip Camera
          </Button>
          <Button variant="contained" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
      {/* </div> */}
    </Dialog>
  );
};

export default WebcamCaptureComponent;
