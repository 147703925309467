import mangalsutraImage1 from "../assets/mangalsutra1.jpg";
import mangalsutraImage2 from "../assets/mangalsutra2.jpg";
import mangalsutraImage3 from "../assets/mangalsutra3.jpg";
import mangalsutraImage4 from "../assets/mangalsutra4.jpg";
import mangalsutraImage5 from "../assets/mangalsutra5.jpg";
import newArrivalImage1 from "../assets/newArrival1.png";
import newArrivalImage2 from "../assets/newArrival2.png";
import newArrivalImage3 from "../assets/newArrival3.png";
import newArrivalImage4 from "../assets/newArrival4.png";
import newArrivalImage5 from "../assets/newArrival5.png";
import bridalJewellery1 from "../assets/jewellery2.jpg";
import bridalJewellery2 from "../assets/jewellery3.jpg";
import bridalJewellery3 from "../assets/jewellery4.png";
import bridalJewellery4 from "../assets/jewellery5.jpg";
import bridalJewellery5 from "../assets/jewellery.jpg";
import dailyWearJewellery1 from "../assets/gold_chain_1.webp";
import dailyWearJewellery2 from "../assets/gold_chain_2.webp";
import dailyWearJewellery3 from "../assets/gold_chain_3.webp";
import dailyWearJewellery4 from "../assets/gold_chain_4.webp";
import dailyWearJewellery5 from "../assets/gold_chain_5.webp";
import expertsChoice1 from "../assets/gold_chain_1.webp";
import expertsChoice2 from "../assets/gold_chain_2.webp";
import expertsChoice3 from "../assets/gold_chain_3.webp";
import expertsChoice4 from "../assets/gold_chain_4.webp";
import expertsChoice5 from "../assets/gold_chain_5.webp";

const popularItemsData = {
  "Elegant Mangalsutra": [
    {
      id: 1,
      name: "Royal Mangalsutra",
      image: mangalsutraImage1,
      price: 1200,
    },
    {
      id: 2,
      name: "Classic Mangalsutra",
      image: mangalsutraImage2,
      price: 1500,
    },
    {
      id: 3,
      name: "Timeless Mangalsutra",
      image: mangalsutraImage3,
      price: 1300,
    },
    {
      id: 4,
      name: "Heritage Mangalsutra",
      image: mangalsutraImage4,
      price: 1400,
    },
    {
      id: 5,
      name: "Graceful Mangalsutra",
      image: mangalsutraImage5,
      price: 1300,
    },
  ],
  "Latest Arrivals": [
    {
      id: 6,
      name: "Gemstone Necklace",
      image: newArrivalImage1,
      price: 2000,
    },
    {
      id: 7,
      name: "Radiance Necklace",
      image: newArrivalImage2,
      price: 2500,
    },
    {
      id: 8,
      name: "Diamond Necklace",
      image: newArrivalImage3,
      price: 2500,
    },
    {
      id: 9,
      name: "Diamond Necklace",
      image: newArrivalImage4,
      price: 2500,
    },
    {
      id: 10,
      name: "Elegance Necklace",
      image: newArrivalImage5,
      price: 2500,
    },
  ],
  "Bridal Collection": [
    {
      id: 11,
      name: "Majestic Bridal Set",
      image: bridalJewellery1,
      price: 2600,
    },
    {
      id: 12,
      name: "GemStone Necklace",
      image: bridalJewellery2,
      price: 2900,
    },
    {
      id: 13,
      name: "Diamond Necklace",
      image: bridalJewellery3,
      price: 2800,
    },
    {
      id: 14,
      name: "Grace Bridal Set",
      image: bridalJewellery4,
      price: 2300,
    },
    {
      id: 15,
      name: "Diamond Necklace",
      image: bridalJewellery5,
      price: 2400,
    },
  ],
  "Daily Wear Jewellery": [
    {
      id: 16,
      name: "Diamond Necklace",
      image: dailyWearJewellery1,
      price: 2000,
    },
    {
      id: 17,
      name: "Diamond Necklace",
      image: dailyWearJewellery2,
      price: 2100,
    },
    {
      id: 18,
      name: "Diamond Necklace",
      image: dailyWearJewellery3,
      price: 2200,
    },
    {
      id: 19,
      name: "Diamond Necklace",
      image: dailyWearJewellery4,
      price: 2300,
    },
    {
      id: 20,
      name: "Diamond Necklace",
      image: dailyWearJewellery5,
      price: 2400,
    },
  ],
  "Trending Picks": [
    {
      id: 21,
      name: "Diamond Necklace",
      image: expertsChoice1,
      price: 2000,
    },
    {
      id: 22,
      name: "Diamond Necklace",
      image: expertsChoice2,
      price: 2520,
    },
    {
      id: 23,
      name: "Diamond Necklace",
      image: expertsChoice3,
      price: 2000,
    },
    {
      id: 24,
      name: "Diamond Necklace",
      image: expertsChoice4,
      price: 2400,
    },
    {
      id: 25,
      name: "Diamond Necklace",
      image: expertsChoice5,
      price: 2300,
    },
  ],
  // Add more popular items as needed
};

export default popularItemsData;
