// Initial.js
import React, { useState, useCallback } from "react";
import { FaSearch, FaBarcode } from "react-icons/fa";
import _ from "lodash";
import "./Initial.css";
import goldItems from "../../data/GlodItems";
import popularItemsData from "../../data/PopularItems";
import similarItemsData from "../../data/SimilarItems";
import SimilarItems from "../SimilarItems/SimilarItems";
import search from "../../assets/search.png";
import Button from 'react-bootstrap/Button';
const Initial = ({
  searchTerm,
  setSearchTerm,
  handleVisualSearchClick,
  selectedItems,
  setSelectedItems,
  finalizedItems,
  handleSendToBilling,
  handleCheckboxChange,
  handleTryOnClick,
  isFinalized,
}) => {
  const [clickedCategory, setClickedCategory] = useState(null);
  const [clickedProductCategory, setClickedProductCategory] = useState(null);
  const [similarItems, setSimilarItems] = useState([]);
  const [selectedSimilarItems, setSelectedSimilarItems] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const showAllSimilarItems = () => {
    setSimilarItems(similarItemsData.necklaces);
    setShowDropdown(false);
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setShowDropdown(true);
  };

  const debouncedInputChange = useCallback(
    _.debounce(handleInputChange, 300),
    []
  );

  const handleInputFocus = () => {
    setShowDropdown(true);
  };
  const handleInputBlur = () => {
    setShowDropdown(false);
  };

  const handleMouseDown = (e) => {
    if (
      !e.target.closest(".dropdown-content") &&
      !e.target.closest(".search-input")
    ) {
      setShowDropdown(false);
    }
  };

  const handleCategoryClick = (index) => {
    setClickedCategory(index);
    setClickedProductCategory(null);
    setShowDropdown(true);
  };

  const handleProductCategoryClick = (index) => {
    setClickedProductCategory(index);
    setClickedCategory(null);
    setShowDropdown(true);
  };

  const handleSubCategoryClick = () => {
    showAllSimilarItems();
  };

  const handleResultItemClick = () => {
    showAllSimilarItems();
  };

  const handleSelectSimilarItem = (item) => {
    if (selectedSimilarItems.includes(item)) {
      setSelectedSimilarItems(
        selectedSimilarItems.filter((i) => i.id !== item.id)
      );
    } else {
      setSelectedSimilarItems([...selectedSimilarItems, item]);
    }
  };

  const handleSimilarItemsDone = () => {
    const updatedSelectedItems = [...selectedItems, ...selectedSimilarItems];
    setSelectedItems(updatedSelectedItems);
    setSimilarItems([]);
    setSelectedSimilarItems([]);
  };

  return (
    <div className="vh-100 body-container" onMouseDown={handleMouseDown}>
      <header className="header">
        <div className="search-bar-container">
          <input
            type="text"
            placeholder="Search for gold products..."
            value={searchTerm}
            onChange={debouncedInputChange}
            onFocus={handleInputFocus}
            // onBlur={handleInputBlur} 
            className="search-input"
          />
          <div className="search-icons">
            <FaSearch onClick={showAllSimilarItems} className="search-icon" />
            <img
              src={search}
              onClick={handleVisualSearchClick}
              className="search-icon1"
            />
            <FaBarcode onClick={showAllSimilarItems} className="search-icon" />
          </div>
        </div>
      </header>

      {showDropdown && (
        <div className="dropdown-content">
          <div className="dropdown-columns">
            <div className="column categories">
              <h3>Categories</h3>
              {goldItems.map((category, index) => (
                <div
                  key={category.name}
                  className="category-item"
                  onClick={() => handleCategoryClick(index)}
                >
                  {category.name}
                </div>
              ))}
            </div>
            <div className="column popular-products">
              <h3>Popular Products</h3>
              {Object.keys(popularItemsData).map((category, index) => (
                <div
                  key={category}
                  className="popular-item"
                  onClick={() => handleProductCategoryClick(index)}
                >
                  {category}
                </div>
              ))}
            </div>
            <div className="column image-display">
              {clickedCategory !== null && (
                <div className="image-grid">
                  {goldItems[clickedCategory].subCategories.map(
                    (subCategory) => (
                      <div
                        key={subCategory.name}
                        className="image-item"
                        onClick={handleSubCategoryClick}
                      >
                        <img
                          src={subCategory.image}
                          alt={subCategory.name}
                          className="displayed-image"
                        />
                        <span>{subCategory.name}</span>
                      </div>
                    )
                  )}
                </div>
              )}
              {clickedProductCategory !== null && (
                <div className="image-grid">
                  {popularItemsData[
                    Object.keys(popularItemsData)[clickedProductCategory]
                  ].map((item) => (
                    <div
                      key={item.id}
                      className="image-item"
                      onClick={handleResultItemClick}
                    >
                      <img
                        src={item.image}
                        alt={item.name}
                        className="displayed-image"
                      />
                      <span>{item.name}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Display all similar items */}
      {similarItems.length > 0 && (
     
        <div className="similar-items-drop">
            <Button variant="success"    onClick={handleSimilarItemsDone}
            disabled={selectedSimilarItems.length === 0}
            className="done-button ml-auto">Done</Button>
          {/* <button
            onClick={handleSimilarItemsDone}
            disabled={selectedSimilarItems.length === 0}
            className="done-button ml-auto"
          >
            Done
          </button> */}
          <SimilarItems
            similarItems={similarItems}
            selectedItems={selectedSimilarItems}
            onSelectItem={handleSelectSimilarItem}
          />
          {/* <button
            onClick={handleSimilarItemsDone}
            disabled={selectedSimilarItems.length === 0}
            className="done-button"
          >
            Done
          </button> */}
        </div>
      )}

      {/* Only show "Selected Items" if not finalized */}
      {!isFinalized && selectedItems.length > 0 && (
        <div className="selected-items">
          <div className="final">
            <h2>Item list</h2>
            {selectedItems.some((item) => item.selected) && (
              <div className="billing-section">
                <button
                  onClick={handleSendToBilling}
                  className="send-to-billing-button"
                >
                  Send to Billing
                </button>
              </div>
            )}
          </div>
          {selectedItems.map((item, index) => (
            <div key={item.id} className="item">
              <div className="item-image">
                <img src={item.image} alt={item.name} />
              </div>
              <div className="item-details">
                <div className="item-meta">
                  <div className="item-data">
                  <span className="item-description">{item.name}</span>
                  <span className="item-weight">Weight: {item.weight}</span>
                  <span className="item-price">Price: <span>${item.price}</span></span>
                  </div>
                </div>
              </div>
              <div className="item-icons">
                <input
                  type="checkbox"
                  checked={item.selected || false}
                  onChange={() => handleCheckboxChange(index)}
                />
                <button onClick={handleTryOnClick} className="tryon-button">
                  Try Now
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Show "Finalized Items" only if finalized */}
      {isFinalized && finalizedItems.length > 0 && (
        <div className="finalized-items">
          <h2>Finalized Items</h2>
          {finalizedItems.map((item, index) => (
            <div key={item.id} className="item">
              <div className="item-image">
                <img src={item.src} alt={item.name} />
              </div>
              <div className="item-details">
                <div className="item-meta">
                  <span className="item-description">{item.name}</span>
                  <span className="item-weight">Weight: {item.weight}</span>
                  <span className="item-price">Price: ${item.price}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Initial;
