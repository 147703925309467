import newArrivalImage1 from "../assets/newArrival1.png";
import newArrivalImage2 from "../assets/newArrival2.png";
import newArrivalImage3 from "../assets/newArrival3.png";
import newArrivalImage4 from "../assets/newArrival4.png";
import newArrivalImage5 from "../assets/newArrival5.png";
import bridalJewellery1 from "../assets/jewellery2.jpg";
import bridalJewellery2 from "../assets/jewellery3.jpg";
import bridalJewellery3 from "../assets/jewellery4.png";
import bridalJewellery4 from "../assets/jewellery5.jpg";
import bridalJewellery5 from "../assets/jewellery.jpg";

// import dailyWearJewellery1 from "../assets/gold_chain_1.jpg";
// import dailyWearJewellery2 from "../assets/gold_chain_2.jpg";
// import dailyWearJewellery3 from "../assets/gold_chain_3.jpg";
// import dailyWearJewellery4 from "../assets/gold_chain_4.jpg";
// import dailyWearJewellery5 from "../assets/gold_chain_5.jpg";
// import expertsChoice1 from "../assets/gold_chain_5.jpg";
// import expertsChoice2 from "../assets/gold_chain_5.jpg";
// import expertsChoice3 from "../assets/gold_chain_5.jpg";
// import expertsChoice4 from "../assets/gold_chain_5.jpg";
// import expertsChoice5 from "../assets/gold_chain_5.jpg";
import jewelleryImage1 from "../assets/gold_earring_1.png";
import jewelleryImage2 from "../assets/gold_earring_2.png";
import jewelleryImage3 from "../assets/gold_earring_3.png";
import jewelleryImage4 from "../assets/gold_earring_4.png";
import jewelleryImage5 from "../assets/gold_earring_5.png";

const SimilarItemsData = {
  necklaces: [
    {
      id: 1,
      name: "Diamond Earrings",
      weight: "12gms",
      image: jewelleryImage1,
      price: 1200,
    },
    {
      id: 2,
      name: "Jumkas",
      weight: "12gms",
      image: jewelleryImage2,
      price: 1200,
    },
    {
      id: 3,
      name: "Gemstone Earrings",
      weight: "14gms",
      image: jewelleryImage3,
      price: 1400,
    },
    {
      id: 4,
      name: "Diamond Earrings",
      weight: "16gms",
      image: jewelleryImage4,
      price: 1600,
    },
    {
      id: 5,
      name: "kasu Gold Ear Wear",
      weight: "15gms",
      image: jewelleryImage5,
      price: 1500,
    },
    {
      id: 6,
      name: "GemStone Necklace",
      weight: "20gms",
      image: newArrivalImage1,
      price: 2000,
    },
    {
      id: 7,
      name: "Temple Jewellery Gold Necklace",
      weight: "25gms",
      image: newArrivalImage2,
      price: 2500,
    },
    {
      id: 8,
      name: "Diamond necklace",
      weight: "25gms",
      image: newArrivalImage3,
      price: 2500,
    },
    {
      id: 9,
      name: "Diamond Necklace",
      weight: "25gms",
      image: newArrivalImage4,
      price: 2500,
    },
    {
      id: 10,
      name: "Temple Jewellery",
      weight: "25gms",
      image: newArrivalImage5,
      price: 2500,
    },

    {
      id: 11,
      name: "Temple Jewellery",
      weight: "26gms",
      image: bridalJewellery1,
      price: 2600,
    },

    {
      id: 12,
      name: "Gem stone Necklace",
      weight: "29gms",
      image: bridalJewellery2,
      price: 2900,
    },
    {
      id: 13,
      name: "Diamond Necklace",
      weight: "28gms",
      image: bridalJewellery3,
      price: 2800,
    },
    {
      id: 14,
      name: "Traditional Gold Necklace",
      weight: "23gms",
      image: bridalJewellery4,
      price: 2300,
    },
    {
      id: 15,
      name: "Diamond Necklace",
      weight: "24gms",
      image: bridalJewellery5,
      price: 2400,
    },

    // {
    //   id: 16,
    //   name: "Daily Wear Gold Chain",
    //   image: dailyWearJewellery1,
    //   price: 2000,
    // },
    // {
    //   id: 17,
    //   name: "Simple Gold Necklace",
    //   image: dailyWearJewellery2,
    //   price: 2100,
    // },
    // {
    //   id: 18,
    //   name: "Casual Gold Chain",
    //   image: dailyWearJewellery3,
    //   price: 2200,
    // },
    // {
    //   id: 19,
    //   name: "Everyday Gold Necklace",
    //   image: dailyWearJewellery4,
    //   price: 2300,
    // },
    // {
    //   id: 20,
    //   name: "Minimalist Gold Chain",
    //   image: dailyWearJewellery5,
    //   price: 2400,
    // },

    // {
    //   id: 21,
    //   name: "Expert's Choice Gold Chain",
    //   image: expertsChoice1,
    //   price: 2000,
    // },
    // {
    //   id: 22,
    //   name: "Exclusive Gold Necklace",
    //   image: expertsChoice2,
    //   price: 2520,
    // },
    // {
    //   id: 23,
    //   name: "Designer Gold Chain",
    //   image: expertsChoice3,
    //   price: 2000,
    // },
    // {
    //   id: 24,
    //   name: "Premium Gold Necklace",
    //   image: expertsChoice4,
    //   price: 2400,
    // },
    // {
    //   id: 25,
    //   name: "Luxury Gold Chain",
    //   image: expertsChoice5,
    //   price: 2300,
    // },
  ],
  // Add more popular items as needed
};

export default SimilarItemsData;
