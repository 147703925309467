import React from "react";
import "./SimilarItems.css";
import { AiFillHeart,AiOutlineCheck } from "react-icons/ai";
const SimilarItems = ({ similarItems, selectedItems, onSelectItem }) => {
  return (
    <div className="items-grid">
      {similarItems.map((item) => (
        <div key={item.id} className="similar-item" onClick={() => onSelectItem(item)}>
          <img src={item.image} alt={item.name} />
          <p>{item.name}</p>
          <p>${item.price}</p>
          <div onClick={() => onSelectItem(item)}>
            {selectedItems.includes(item) ? <button><AiOutlineCheck/> Selected</button>  : <button><AiFillHeart/> Wishlist</button>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SimilarItems;
